import { graphql } from "gatsby"
import { Helmet } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { Heading } from "../components/01_atoms/Heading/Heading"
import { Paragraph } from "../components/01_atoms/Paragraph/Paragraph"
import ImageTextBlock from "../components/03_organisms/ImageTextBlock/ImageTextBlock"
import { RenderComponent } from "../components/components"
import Layout from "../components/layout"
import Seo from "../components/seo"

const JobsPage = ({ data }) => {
  const pageData = data.allNodeJobs.nodes[0]
  const components = pageData.relationships.field_components
  const locations = data.allNodeLocation.nodes
  return (
    <Layout>
      <Seo
        title={pageData.field_meta_title}
        description={pageData.field_meta_description}
      />
      <Helmet
        bodyAttributes={{
          class: "jobs-page",
        }}
      />

      <ImageTextBlock
        height="100"
        className="hero"
        image={pageData.relationships.field_image}
        image_position="left"
        slanted
      >
        <Heading size="xxl" spacing="l">
          {pageData.title}
        </Heading>
        <Paragraph>
          <span dangerouslySetInnerHTML={{ __html: pageData.body.processed }} />
        </Paragraph>
      </ImageTextBlock>

      {components?.map((component, index) =>
        RenderComponent(component, index, locations)
      )}
    </Layout>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */

export default JobsPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allNodeJobs(filter: { status: { eq: true }, langcode: { eq: $language } }) {
      nodes {
        title
        body {
          processed
        }
        field_meta_description
        field_meta_title
        internal {
          type
        }
        path {
          alias
        }
        relationships {
          field_image {
            ...imageFragment
          }
          field_components {
            ... on Node {
              ...contentList
              ...imageTextFragment
              ...standout
              ...locationList
              ...testimonialsCarousel
              ...videoFragment
            }
          }
        }
      }
    }
    allNodeLocation(
      filter: { status: { eq: true }, langcode: { eq: $language } }
      sort: { fields: field_weight, order: ASC }
    ) {
      nodes {
        path {
          alias
          langcode
        }
        field_external_jobs_url {
          uri
        }
        field_spontaneous_appl_url {
          url
        }

        relationships {
          field_country {
            name
            field_country
            relationships {
              field_background_color {
                name
              }
              field_image {
                ...imageFragment
              }
            }
          }
        }
      }
    }
  }

  fragment testimonialsCarousel on paragraph__testimonial_carousel {
    internal {
      type
    }
    relationships {
      field_testimonials {
        internal {
          type
        }
        field_content {
          processed
        }
        relationships {
          field_background_color {
            internal {
              type
            }
            name
          }
          field_image {
            ...imageFragment
          }
        }
      }
    }
  }

  fragment locationList on paragraph__locations_list {
    internal {
      type
    }
    field_content {
      processed
    }
    field_locations_title
    field_title
  }
`
